<template>
  <v-container class="mt-2" fluid>
    <v-card rounded class="pa-2">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols="auto" :md="2" class="mt-2">
            <span>
              {{$_strings.locations.TYPE_LOCATION}}
            </span>
          </v-col>
          <v-col cols="auto" class="mt-2">
            :
          </v-col>
          <v-col :cols="12" md>
            <v-select
              v-model="form.locationType"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.TYPE_LOCATION)]"
              :label="$_strings.locations.TYPE_LOCATION"
              required
              :items="itemsLocationType"
              clearable
              solo
              outlined
              dense
              flat
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto" :md="2" class="mt-2">
            <span>
              {{$_strings.locations.LOCATION_NAME_INPUT_LABEL}}
            </span>
          </v-col>
          <v-col cols="auto" class="mt-2">
            :
          </v-col>
          <v-col :cols="12" md>
            <v-text-field
              v-model="form.name"
              v-mask="{'alias': 'no-special-characters-allowed-for-location-name'}"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.LOCATION_NAME_INPUT_LABEL)]"
              :label="$_strings.locations.LOCATION_NAME_INPUT_LABEL"
              required
              clearable
              solo
              outlined
              dense
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto" :md="2" class="mt-md-2">
            <span>
              {{$_strings.locations.ADDRESS_INPUT_LABEL}}
            </span>
          </v-col>
          <v-col cols="auto" class="mt-md-2">
            :
          </v-col>
          <v-col :cols="12" md>
            <vuetify-google-autocomplete
              id="map"
              clearable
              :value="form.address"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.ADDRESS_INPUT_LABEL)]"
              :label="$_strings.locations.ADDRESS_INPUT_LABEL"
              @placechanged="placePickedHandler"
              @change.native="autoCompleteChanged"
              solo
              outlined
              dense
              flat
              types=""
            >
            </vuetify-google-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="2" class="mt-2">
            <span>
              {{$_strings.locations.POSITION_INPUT_LABEL}}
            </span>
          </v-col>
          <v-col cols="auto" class="mt-2">
            :
          </v-col>
          <v-col class="py-0">
            <GmapMap
              @center_changed="updatePosition"
              :center="mapCenter"
              :zoom="15"
              map-type-id="terrain"
              class="w-100 h5"
              :options="{
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                disableDefaultUi: true
              }"
            >
              <GmapMarker
                :position="{
                  lat: form.latitude,
                  lng: form.longitude
                }"
                :clickable="true"
                :icon="{url : require('@/assets/images/yellow-truck-map-marker-icon.png')}"
              />
            </GmapMap>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" md="3">
            <v-text-field
              v-model="form.latitude"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.LATITUDE_INPUT_LABEL)]"
              :label="$_strings.locations.LATITUDE_INPUT_LABEL"
              type="number"
              @change="latlongChanged"
              required
              clearable
            ></v-text-field>
          </v-col>
          <v-col class="py-0" md="3">
            <v-text-field
              v-model="form.longitude"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.LONGITUDE_INPUT_LABEL)]"
              :label="$_strings.locations.LONGITUDE_INPUT_LABEL"
              type="number"
              @change="latlongChanged"
              required
              clearable
            ></v-text-field>
          </v-col>
          <v-col class="py-0" md="3">
            <v-text-field
              v-model="form.radiusCalculation"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.RADIUS_INPUT_LABEL)]"
              :label="$_strings.locations.RADIUS_INPUT_LABEL"
              type="number"
              required
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-autocomplete
              :search-input.sync="provinceSearch"
              :loading="provinceLoading"
              v-model="form.province"
              :rules="[v => !!v.id || $_strings.messages.validation.REQUIRED($_strings.locations.PROVINCE_INPUT_LABEL)]"
              :label="$_strings.locations.PROVINCE_INPUT_LABEL"
              item-text="name"
              item-value="id"
              return-object
              :items="provinceList"
              @change="provinceChanged"
              required
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-autocomplete
              :search-input.sync="citySearch"
              :loading="cityLoading"
              v-model="form.city"
              :rules="[v => !!v.id || $_strings.messages.validation.REQUIRED($_strings.locations.CITY_INPUT_LABEL)]"
              :label="$_strings.locations.CITY_INPUT_LABEL"
              item-text="name"
              item-value="id"
              :items="cityList"
              @change="cityChanged"
              return-object
              required
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-autocomplete
              :search-input.sync="districtSearch"
              :loading="districtLoading"
              v-model="form.district"
              :rules="[v => !!v.id || $_strings.messages.validation.REQUIRED($_strings.locations.DISTRICT_INPUT_LABEL)]"
              :label="$_strings.locations.DISTRICT_INPUT_LABEL"
              item-text="name"
              item-value="id"
              :items="districtList"
              @change="districtChanged"
              return-object
              required
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-autocomplete
              :search-input.sync="subDistrictSearch"
              :loading="subDistrictLoading"
              v-model="form.subDistrict"
              :rules="[v => !!v.id || $_strings.messages.validation.REQUIRED($_strings.locations.SUBDISTRICT_INPUT_LABEL)]"
              :label="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
              item-text="name"
              item-value="id"
              :items="subDistrictList"
              return-object
              required
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-5" md="3">
            <v-text-field
              :label="$_strings.locations.OPENING_TIME_INPUT_LABEL"
              v-mask="{'alias': 'time'}"
              outlined
              dense
              prepend-inner-icon="mdi-clock-time-four-outline"
              v-model="form.openingTime"
            ></v-text-field>
          </v-col>
          <v-col class="mt-5" md="3">
            <v-text-field
              :label="$_strings.locations.CLOSING_TIME_INPUT_LABEL"
              v-mask="{'alias': 'time'}"
              outlined
              dense
              prepend-inner-icon="mdi-clock-time-four-outline"
              v-model="form.closingTime"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="form.locationNote"
              no-resize
              outlined
              :label="$_strings.locations.LOCATION_NOTE_INPUT_LABEL"
              rows="5"
              :counter="255"
              :rules="[(v) => rulesNotes(v, false, 255, 'Tambahkan Informasi (opsional)')]"
            ></v-textarea>
          </v-col>
        </v-row>
        <br>
        <br>
        <v-row>
          <v-col cols="auto">
            <v-btn @click="$router.go(-1)">
              {{$_strings.common.BACK}}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" v-if="userAccess.canUpdate || userAccess.canCreate">
            <v-btn color="primary" @click="submit">
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { rulesNotes } from '../../helper/commonHelpers';

export default {
  name: 'LocationCreate',
  created() {
    const { data, id } = this.$route.params;
    if (data) {
      this.setData(data);
    } else if (id) {
      this.fetchLocationDetail();
    } else {
      this.fetchSelectList('', 'province');
    }
  },
  data() {
    return {
      valid: false,
      form: {
        name: '',
        address: '',
        latitude: 0,
        longitude: 0,
        province: {},
        city: {},
        district: {},
        subDistrict: {},
        radiusCalculation: 200,
        openingTime: '00:00',
        closingTime: '00:00',
        locationNote: null,
        locationType: '',
      },
      mapCenter: {
        lat: -6.1753917,
        lng: 106.82715329999996,
      },
      provinceSearch: null,
      provinceLoading: false,
      provinceList: [],
      citySearch: null,
      cityLoading: false,
      cityList: [],
      districtSearch: null,
      districtLoading: false,
      districtList: [],
      subDistrictSearch: null,
      subDistrictLoading: false,
      subDistrictList: [],
      itemsLocationType: [
        {
          text: 'Laut',
          value: 'SEA',
        },
        {
          text: 'Udara',
          value: 'AIR',
        },
        {
          text: 'Darat',
          value: 'LAND',
        },
      ],
    };
  },
  computed: {
    locationId() {
      return this.$route.params.id;
    },
  },
  watch: {
    provinceSearch(val) {
      if (this.form.province && this.form.province.name === this.provinceSearch) return;
      if (this.provinceLoading) return;
      this.fetchSelectList(val, 'province');
    },
    citySearch(val) {
      if (this.form.city && this.form.city.name === this.citySearch) return;
      if (this.cityLoading) return;
      this.fetchSelectList(val, 'city');
    },
    districtSearch(val) {
      if (this.form.district && this.form.district.name === this.districtSearch) return;
      if (this.districtLoading) return;
      this.fetchSelectList(val, 'district');
    },
    subDistrictSearch(val) {
      if (this.form.subDistrict && this.form.subDistrict.name === this.subDistrictSearch) return;
      if (this.subDistrictLoading) return;
      this.fetchSelectList(val, 'subDistrict');
    },
  },
  methods: {
    rulesNotes,
    setData(data) {
      this.form = {
        ...this.form,
        ...data,
      };
      this.provinceList.push(data.province);
      this.cityList.push(data.city);
      this.districtList.push(data.district);
      this.subDistrictList.push(data.subDistrict);
      this.mapCenter = {
        lat: this.form.latitude,
        lng: this.form.longitude,
      };
    },
    fetchLocationDetail() {
      this.$root.$loading.show();
      this.$_services.locations.fetchLocationDetail(this.$route.params.id)
        .then((result) => {
          this.setData(result.data);
        }).finally((e) => {
          this.$root.$loading.hide();
        });
    },
    updatePosition(event) {
      this.form = {
        ...this.form,
        latitude: event.lat(),
        longitude: event.lng(),
      };
    },
    latlongChanged() {
      this.form.latitude = Number(this.form.latitude);
      this.form.longitude = Number(this.form.longitude);
      // this.reverseGeocoding(this.form.latitude, this.form.longitude);
      this.mapCenter = {
        lat: this.form.latitude,
        lng: this.form.longitude,
      };
    },
    reverseGeocoding(latitude, longitude) {
      this.$root.$loading.show();
      this.$_services.locations.reverseGeo(latitude, longitude)
        .then((result) => {
        }).catch((err) => {
          if (err.data && err.data.message) {
            this.$dialog.notify.error(err.data.message);
          }
        })
        .finally((e) => {
          this.$root.$loading.hide();
        });
    },
    autoCompleteChanged(event) {
      this.form.address = event.target.value;
    },
    placePickedHandler(place, placeDetail) {
      this.form = {
        ...this.form,
        address: placeDetail.formatted_address,
        latitude: placeDetail.geometry.location.lat(),
        longitude: placeDetail.geometry.location.lng(),
      };
      this.mapCenter = {
        lat: placeDetail.geometry.location.lat(),
        lng: placeDetail.geometry.location.lng(),
      };
    },
    provinceChanged(input) {
      this.cityList = [];
      this.districtList = [];
      this.subDistrictList = [];
      this.form = {
        ...this.form,
        city: {},
        district: {},
        subDistrict: {},
      };
      this.fetchSelectList('', 'city');
    },
    cityChanged(input) {
      this.districtList = [];
      this.subDistrictList = [];
      this.form = {
        ...this.form,
        district: {},
        subDistrict: {},
      };
      this.fetchSelectList('', 'district');
    },
    districtChanged(input) {
      this.subDistrictList = [];
      this.form = {
        ...this.form,
        subDistrict: {},
      };
      this.fetchSelectList('', 'subDistrict');
    },
    fetchSelectList(input, type) {
      switch (type) {
        case 'province':
          this.provinceLoading = true;
          this.$_services.locations.provinces({
            page: 0,
            size: 50,
          })
            .then((result) => {
              this.provinceList = result.data.contents;
            })
            .finally((e) => {
              this.provinceLoading = false;
            });
          break;
        case 'city':
          if (!this.form.province || !this.form.province.id) return;
          this.cityLoading = true;
          this.$_services.locations.cities(input, this.form.province.id)
            .then((result) => {
              this.cityList = result.data.contents;
            })
            .finally((e) => {
              this.cityLoading = false;
            });
          break;
        case 'district':
          if (!this.form.city || !this.form.city.id) return;
          this.districtLoading = true;
          this.$_services.locations.districts(input, this.form.city.id)
            .then((result) => {
              this.districtList = result.data.contents;
            })
            .finally((e) => {
              this.districtLoading = false;
            });
          break;
        case 'subDistrict':
          if (!this.form.district || !this.form.district.id) return;
          this.subDistrictLoading = true;
          this.$_services.locations.subDistricts(input, this.form.district.id)
            .then((result) => {
              this.subDistrictList = result.data.contents;
            })
            .finally((e) => {
              this.subDistrictLoading = false;
            });
          break;
        default:
          break;
      }
    },
    submit() {
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return;
      }
      this.$root.$loading.show();
      this.$_services.locations.createEditLocation(this.form, this.locationId)
        .then((res) => {
          if (this.locationId) {
            this.$dialog.notify.success(this.$_strings.locations.SUCCESS_EDIT_LOCATION_MESSAGE_TEXT);
          } else {
            this.$dialog.notify.success(this.$_strings.locations.SUCCESS_CREATE_LOCATION_MESSAGE_TEXT);
          }
          this.$router.go(-1);
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
  },
};
</script>
